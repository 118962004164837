import React from "react";

import { Collapse } from "antd";

import plus_collapse from "../../Assets/Images/plus_collapse.svg";
import minus_collapse from "../../Assets/Images/minus_collapse.svg";

const { Panel } = Collapse;

const GeneralInfo = ({
  information,
  title,
  expandedKeys,
  onExpand,
  searchValue,
}) => {
  const extractText = (jsx) => {
    if (typeof jsx === "string") {
      return jsx;
    } else if (React.isValidElement(jsx)) {
      return React.Children.map(jsx.props.children, (child) =>
        extractText(child)
      )?.join("");
    }
    return "";
  };

  const filteredInformation = information.filter((item) => {
    const descriptionText = extractText(item.description).toLowerCase();
    return (
      item.header.toLowerCase().includes(searchValue.toLowerCase()) ||
      descriptionText.includes(searchValue.toLowerCase())
    );
  });
  return (
    <div>
      <p className="kl-pi-subdivtitle">{title}</p>
      <Collapse
        className="help-desk-collapse"
        bordered={false}
        activeKey={expandedKeys}
        onChange={(keys) => onExpand(keys)}
        expandIcon={({ isActive }) =>
          isActive ? (
            <img src={minus_collapse} alt="plus_collapse" />
          ) : (
            <img src={plus_collapse} alt="plus_collapse" />
          )
        }
      >
        {filteredInformation.map((item, index) => (
          <Panel
            key={index}
            header={
              <div
                dangerouslySetInnerHTML={{
                  __html: item.header,
                }}
              />
            }
          >
            <p
              className="helpdesk-description"
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default GeneralInfo;
