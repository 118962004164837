import React, { useState } from "react";

import Download_icon from "../../../Assets/Images/download_icon.svg";
import View_icon from "../../../Assets/Images/view_icon.svg";

import Borrower_pdf from "../../../Assets/Images/borrower_pdf.svg";
import { Col, Modal, Row, Spin, Tooltip } from "antd";
import GlobalVariabels from "../../../Utils/GlobalVariabels";
import axios from "axios";
import { getFilenameDetails } from "../../../Utils/Reusables";
import { LoadingOutlined } from "@ant-design/icons";

const Document = ({ TrancheRes }) => {
  const [downloadingFile, setDownloadingFile] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleView = (docs) => {
    const docsfile = `${GlobalVariabels.VIEW_IMG}/${docs}`;
    setModalVisible(true);
    setModalContent(docsfile);
  };

  const handleDownload = async (fileReference, fileName) => {
    if (fileReference) {
      setDownloadingFile((prevState) => ({
        ...prevState,
        [fileReference]: true,
      }));
      const fileUrl = `${GlobalVariabels.VIEW_IMG}/${fileReference}`;
      try {
        const response = await axios({
          url: fileUrl,
          method: "GET",
          responseType: "blob",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setDownloadingFile((prevState) => ({
          ...prevState,
          [fileReference]: false,
        }));
      }
    }
  };

  return (
    <Col xs={24} sm={24} md={24} lg={24} className="medium-doc-col">
      <p className="mt-0 tranch-head">Documents</p>
      <Row>
        {TrancheRes?.attachments?.length > 0
          ? TrancheRes?.attachments?.map((file, index) => (
              <React.Fragment key={index}>
                <Col xs={18} lg={18}>
                  <div className="mb-4 mt-0 borrower-info-tag">
                    <div className="borrower-pdf-div">
                      <div>
                        <img src={Borrower_pdf} alt="pdf_icon" />
                      </div>
                      <div>
                        <Tooltip title={file.fileName}>
                          <p className="m-0">
                            {getFilenameDetails(file.fileName)}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} lg={6} className="sb-justify-end-item-center">
                  <div className="borrower-pdf-div">
                    <div
                      onClick={() => handleView(file?.fileReference)}
                      className="cursor-pointer"
                    >
                      <img src={View_icon} alt="view_icon" />
                    </div>
                    <div
                      onClick={() =>
                        handleDownload(file?.fileReference, file.fileName)
                      }
                      className="cursor-pointer"
                    >
                      {downloadingFile[file.fileReference] ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <img src={Download_icon} alt="download_icon" />
                      )}
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            ))
          : "No Document Found"}
      </Row>
      <Modal
        className="sb-pdf-modal"
        centered
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={`https://docs.google.com/gview?url=${modalContent}&embedded=true`}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
    </Col>
  );
};

export default Document;
