import { useEffect, useState } from "react";

const JivoChat = ({ user }) => {
  const [secretKey, setSecretKey] = useState("");

  useEffect(() => {
    const base64UrlEncode = (str) => {
      return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    };

    const generateJWT = async (header, payload, secret) => {
      const encodedHeader = base64UrlEncode(JSON.stringify(header));
      const encodedPayload = base64UrlEncode(JSON.stringify(payload));

      const headerAndPayload = `${encodedHeader}.${encodedPayload}`;
      const signature = await createSignature(headerAndPayload, secret);

      return `${headerAndPayload}.${signature}`;
    };

    const createSignature = async (headerAndPayload, secret) => {
      const encoder = new TextEncoder();
      const key = await crypto.subtle.importKey(
        "raw",
        encoder.encode(secret),
        { name: "HMAC", hash: "SHA-256" },
        false,
        ["sign"]
      );

      const signature = await crypto.subtle.sign(
        "HMAC",
        key,
        encoder.encode(headerAndPayload)
      );

      return base64UrlEncode(String.fromCharCode(...new Uint8Array(signature)));
    };

    if (user && user.number && user.email) {
      const header = {
        alg: "HS256",
        typ: "JWT",
      };

      const payload = {
        name: user.firstName + " " + user.lastName,
        id: user.number,
      };

      const secret =
        "df35f84d2b0944a2270a5456523c7d4f8167a4a7067e248937ca6d94342a3e8d";

      generateJWT(header, payload, secret).then((token) => {
        setSecretKey(token);
      });
    }
  }, [user]);

  useEffect(() => {
    const loadJivoChatScript = () => {
      const jivoScript = document.createElement("script");
      jivoScript.setAttribute("src", "//code.jivosite.com/widget/kpATL5NQe7");
      jivoScript.setAttribute("async", true);
      document.head.appendChild(jivoScript);

      jivoScript.onload = () => {
        const checkJivoApi = setInterval(() => {
          if (window.jivo_api) {
            clearInterval(checkJivoApi);
            window.jivo_api.setUserToken(secretKey);

            window.jivo_api.setContactInfo({
              name: user.name,
              email: user.email,
              phone: user.mobilePhone,
            });

            window.jivo_api.setCustomData([
              {
                title: "User ID",
                content: user.number,
              },
              {
                title: "Name",
                content: user.firstName + " " + user.lastName,
              },
            ]);
          }
        }, 100);
      };
    };

    if (secretKey) {
      loadJivoChatScript();
    }

    return () => {
      const script = document.querySelector(
        'script[src="//code.jivosite.com/widget/kpATL5NQe7"]'
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [secretKey, user]);

  return null;
};

export default JivoChat;
