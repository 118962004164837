/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Pagination, Row, Spin, Table } from "antd";

import Subscription_fees from "../../../Assets/Images/Icons/subscription_fees_tx.svg";
import Deposit from "../../../Assets/Images/Icons/deposit_tx.svg";
import Withdraw_request from "../../../Assets/Images/Icons/withdraw_request_tx.svg";
import Withdraw from "../../../Assets/Images/Icons/withdraw_tx.svg";
import Repaid from "../../../Assets/Images/Icons/repaid_tx.svg";
import Settlement from "../../../Assets/Images/Icons/settlement_tx.svg";
import Internal_ts from "../../../Assets/Images/Icons/internal_ts_tx.svg";
import Referral_payment from "../../../Assets/Images/Icons/referral_payment_tx.svg";
import Repayment from "../../../Assets/Images/Icons/repayment_tx.svg";
import Currency_exchange from "../../../Assets/Images/Icons/currency_exchange_tx.svg";
import Fee from "../../../Assets/Images/Icons/fee_tx.svg";

import buttonActive from "../../../Assets/Images/ButtonActive.svg";
import frame from "../../../Assets/Images/Frame.svg";
import frameActive from "../../../Assets/Images/FrameActive.svg";
import button from "../../../Assets/Images/Button.svg";
import NoInvestmentIcon from "../../../Assets/Images/noinvestement.svg";

import { LoadingOutlined } from "@ant-design/icons";
import TransactionCard from "./TransactionCard";
import {
  britishFormatDateWithTime,
  formatCurrency,
} from "../../../Utils/Reusables";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccStatementFilter,
  setTransactionList,
} from "../../../Redux/Action/Dashboards";
import { AccountStatementSummary } from "../../../Apis/DashboardApi";
import ROUTES from "../../../Config/Routes";
import { useNavigate } from "react-router-dom";

const TransactionTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showButtonActive, setShowButtonActive] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState();
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [transactionRows, setTransactionRows] = useState();

  const filterData = useSelector(
    (state) => state?.dashboards?.DashboardData?.accStatementFilterData
  );

  const postingDateFrom = filterData?.postingDateFrom;
  const postingDateTo = filterData?.postingDateTo;
  const currencyCode = filterData?.currencyCode;
  const txTypeSubtypeCombos = filterData?.txTypeSubtypeCombos;

  useEffect(() => {
    if (localStorage.getItem("accStatementTransactionPageSize") !== null) {
      setItemPerPage(
        parseInt(localStorage.getItem("accStatementTransactionPageSize"))
      );
    }
  }, []);

  useEffect(() => {
    setAccStatementFilter(
      JSON.parse(localStorage.getItem("accStatementFilterData")),
      dispatch
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAccountStatement = (page, items) => {
    setTransactionLoader(true);
    const updatedTxTypeSubtypeCombos = txTypeSubtypeCombos?.map((combo) => {
      if (combo.txType === "SUBSCRIPTION" || combo.txType === "SETTLEMENT") {
        return {
          ...combo,
          txSubType: combo.txSubType?.map((subType) =>
            subType === "FEE" ? "INVESTOR_SUBSCRIPTION_FEE" : subType
          ),
        };
      }
      return combo;
    });
    const payload = {
      page: page,
      pageSize: items,
      postingDateFrom: postingDateFrom || null,
      postingDateTo: postingDateTo || null,
      currencyCode: currencyCode || "USD",
      txTypeSubtypeCombos:
        updatedTxTypeSubtypeCombos?.length > 0
          ? updatedTxTypeSubtypeCombos
          : [],
    };

    AccountStatementSummary(payload)
      .then(async (accountsummary) => {
        if (!accountsummary) {
          setTransactionLoader(false);
          return;
        }

        if (accountsummary && Object.keys(accountsummary)?.length > 0) {
          setTransactionRows(accountsummary?.txs);
          setTotalItem(accountsummary?.totalTxs);
          setCurrencySymbol(accountsummary?.currencySymbol);
          await setTransactionList(accountsummary, dispatch);
          setTransactionLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching account summary:", error);
        setTransactionLoader(false);
      });
  };

  const resetPageAndFetch = () => {
    setCurrentPage(1);
    handleAccountStatement(1, itemsPerPage);
  };

  useEffect(() => {
    if (
      postingDateFrom !== undefined ||
      postingDateTo !== undefined ||
      currencyCode !== undefined ||
      txTypeSubtypeCombos !== undefined ||
      txTypeSubtypeCombos?.length !== 0 ||
      itemsPerPage
    ) {
      resetPageAndFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    txTypeSubtypeCombos,
    postingDateTo,
    postingDateFrom,
    currencyCode,
    itemsPerPage,
  ]);

  const handleButtonToggle = () => {
    setShowButtonActive((prevShowButtonActive) => !prevShowButtonActive);
  };

  const onShowSizeChange = (current, pageSize) => {
    setItemPerPage(pageSize);
    localStorage.setItem("accStatementTransactionPageSize", pageSize);
  };

  const handleChange = (page) => {
    if (currentPage === page) {
      return;
    }
    setCurrentPage(page);
    handleAccountStatement(page, itemsPerPage);
  };

  const transactionColumns = [
    {
      title: "TX ID",
      dataIndex: "tx_id",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Tranche Info",
      dataIndex: "trancheInfo",
    },
    {
      title: "Transaction",
      dataIndex: "transaction",
      width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Amount reserved",
      dataIndex: "amount_reserved",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
  ];

  const transactionData =
    transactionRows?.length > 0 &&
    transactionRows?.map((item) => ({
      key: 1,
      tx_id: item.id,
      date: britishFormatDateWithTime(item?.postingTs),
      trancheInfo: (
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate(`${ROUTES.TRANCH_INVEST}/${item?.trancheUuid}`);
          }}
        >
          <p className="m-0">
            {item?.trancheNumber !== null ? item?.trancheNumber : "-"}
          </p>
          <p className="m-0 transaction-company-head">{item?.trancheTitle}</p>
        </div>
      ),
      transaction:
        item?.txTitle === "Subscription (Principal)" ? (
          <div className="acc-status-div subscription">
            <img src={Subscription_fees} alt="Subscription_fees" />
            <p>Subscription (Principal)</p>
          </div>
        ) : item?.txTitle === "Subscription (Accrued interest)" ? (
          <div className="acc-status-div subscription">
            <img src={Subscription_fees} alt="Subscription_fees" />
            <p>Subscription (Accrued interest)</p>
          </div>
        ) : item?.txTitle === "Subscription (Fee)" ? (
          <div className="acc-status-div subscription">
            <img src={Subscription_fees} alt="Subscription_fees" />
            <p>Subscription (Fee)</p>
          </div>
        ) : item?.txTitle === "SUBSCRIPTION (PRINCIPAL_DISCOUNT)" ? (
          <div className="acc-status-div subscription">
            <img src={Subscription_fees} alt="Subscription_fees" />
            <p>Subscription (Principal Discount)</p>
          </div>
        ) : item?.txTitle === "Deposit" ? (
          <div className="acc-status-div deposit">
            <img src={Deposit} alt="deposit" />
            <p>Deposit</p>
          </div>
        ) : item?.txTitle === "Withdrawal request" ? (
          <div className="acc-status-div widthdrawal-request">
            <img src={Withdraw_request} alt="Subscription_fees" />
            <p>Widthdrawal Request</p>
          </div>
        ) : item?.txType === "REPAID" ? (
          <div className="acc-status-div repaid">
            <img src={Repaid} alt="Subscription_fees" />
            <p>Repaid</p>
          </div>
        ) : item?.txType === "WITHDRAW" ? (
          <div className="acc-status-div withdraw">
            <img src={Withdraw} alt="Subscription_fees" />
            <p>Widthdraw</p>
          </div>
        ) : item?.txTitle === "Settlement (Principal)" ? (
          <div className="acc-status-div settlement">
            <img src={Settlement} alt="Subscription_fees" />
            <p>Settlement (Principal)</p>
          </div>
        ) : item?.txTitle === "Settlement (Fee)" ? (
          <div className="acc-status-div settlement">
            <img src={Settlement} alt="Subscription_fees" />
            <p>Settlement (Fee)</p>
          </div>
        ) : item?.txTitle === "Settlement (Accrued interest)" ? (
          <div className="acc-status-div settlement">
            <img src={Settlement} alt="Subscription_fees" />
            <p>Settlement (Accrued interest)</p>
          </div>
        ) : item?.txTitle === "Repayment (Interest)" ? (
          <div className="acc-status-div repayment">
            <img src={Repayment} alt="Subscription_fees" />
            <p>Repayment (Interest)</p>
          </div>
        ) : item?.txTitle === "Repayment (Principal)" ? (
          <div className="acc-status-div repayment">
            <img src={Repayment} alt="Subscription_fees" />
            <p>Repayment (Principal)</p>
          </div>
        ) : item?.txTitle === "REPAYMENT (WHT)" ? (
          <div className="acc-status-div repayment">
            <img src={Repayment} alt="Subscription_fees" />
            <p>REPAYMENT (WHT)</p>
          </div>
        ) : item?.txTitle === "Currency exchange request" ? (
          <div className="acc-status-div currency-exchange">
            <img src={Currency_exchange} alt="Subscription_fees" />
            <p>Currency Exchange</p>
          </div>
        ) : item?.txTitle === "Currency exchange (in)" ? (
          <div className="acc-status-div currency-exchange">
            <img src={Currency_exchange} alt="Subscription_fees" />
            <p>Currency Exchange (incoming)</p>
          </div>
        ) : item?.txTitle === "Currency exchange (out)" ? (
          <div className="acc-status-div currency-exchange">
            <img src={Currency_exchange} alt="Subscription_fees" />
            <p>Currency Exchange (outgoing)</p>
          </div>
        ) : item?.txType === "REFERRAL_PAYMENT" ? (
          <div className="acc-status-div referral-payment">
            <img src={Referral_payment} alt="Subscription_fees" />
            <p>Referral Payment</p>
          </div>
        ) : item?.txType === "INTERNAL_TRANSFER" ? (
          <div className="acc-status-div internal-transfer">
            <img src={Internal_ts} alt="Subscription_fees" />
            <p>Internal transfer</p>
          </div>
        ) : item?.txTitle === "FEE (INVESTOR_MANAGEMENT_FEE)" ? (
          <div className="acc-status-div fee">
            <img src={Fee} alt="fee_tx" />
            <p>FEE (INVESTOR_MANAGEMENT_FEE)</p>
          </div>
        ) : item?.txTitle === "Reward Granting" ? (
          <div className="acc-status-div fee">
            <img src={Fee} alt="fee_tx" />
            <p>Reward Granting</p>
          </div>
        ) : item?.txTitle === "Reward Revoking" ? (
          <div className="acc-status-div fee">
            <img src={Fee} alt="fee_tx" />
            <p>Reward Revoking</p>
          </div>
        ) : null,
      amount: formatCurrency(currencySymbol, item?.amount),
      amount_reserved: formatCurrency(currencySymbol, item?.amountReserved),
      balance: formatCurrency(currencySymbol, item?.balance),
    }));

  return (
    <div>
      <div className="transaction-grid-btn-div mt-24 mb-8">
        <div>
          <p className="m-0 tranch-head">Transaction</p>
        </div>
        <div className="currency-btn-div">
          {showButtonActive ? (
            <div
              className="transaction-button cursor-pointer"
              onClick={handleButtonToggle}
            >
              <img src={buttonActive} alt="button" />
              <img src={frame} alt="button" />
            </div>
          ) : (
            <div
              className="transaction-button cursor-pointer"
              value="passive"
              onClick={handleButtonToggle}
            >
              <img src={button} alt="button" />
              <img src={frameActive} alt="button" />
            </div>
          )}
        </div>
      </div>
      {showButtonActive && windowWidth <= 768 ? (
        <div>
          {transactionLoader ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 50, color: "var(--kilde-blue)" }}
                />
              }
              spinning={transactionLoader}
            />
          ) : (
            <>
              {transactionRows?.length > 0 ? (
                <Row gutter={[8, 8]}>
                  {transactionRows.map((item, index) => (
                    <Col xs={24} sm={12} key={index}>
                      <TransactionCard
                        item={item}
                        currencySymbol={currencySymbol}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="no-investment-div">
                  <div className="sb-flex-column-item-center">
                    <img src={NoInvestmentIcon} alt="no investment yet" />
                    <p
                      style={{
                        color: "var(--black-20, #1A202C33)",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                      }}
                    >
                      No Investments yet
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <Col className="gutter-row summary-table-div" xs={24} sm={24} md={24}>
          <p className="m-0 tranch-head">Transaction</p>

          <Table
            scroll={{ x: "auto" }}
            columns={transactionColumns}
            dataSource={transactionData}
            className="trache-table outstanding-pay-table w-100"
            pagination={false}
            loading={
              transactionLoader
                ? {
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                  }
                : false
            }
          />
        </Col>
      )}

      {transactionRows?.length > 0 ? (
        <Col xs={24} className="summary-pagination-div">
          <Pagination
            className="tranch-table-pagination"
            pageSize={itemsPerPage}
            current={currentPage}
            total={totalItem}
            onChange={handleChange}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSizeOptions={["10", "20", "50", "100"]}
            locale={{
              items_per_page: " ",
            }}
          />
        </Col>
      ) : null}
    </div>
  );
};

export default TransactionTable;
