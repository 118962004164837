import ROUTES from "../Config/Routes";

import Cookies from "js-cookie";

import { REGTANK_ERROR_MESSAGES } from "./Constant";
import { checkStepStatus, redirectToVue } from "./Helpers";
import { notification } from "antd";
import { formatDistanceToNow } from "date-fns";

// Gray Icons
import General_icon from "../Assets/Images/SVGs/gray_icon/IconSet.svg";
import Getting_start_icon from "../Assets/Images/SVGs/gray_icon/IconSet-1.svg";
import Verif_sec_icon from "../Assets/Images/SVGs/gray_icon/IconSet-2.svg";
import Investing_icon from "../Assets/Images/SVGs/gray_icon/IconSet-3.svg";
import Auto_invest_icon from "../Assets/Images/SVGs/gray_icon/IconSet-4.svg";
import Taxation_icon from "../Assets/Images/SVGs/gray_icon/IconSet-5.svg";

// Blue Icons
import General_blue_icon from "../Assets/Images/SVGs/blue_icon/IconSet.svg";
import Getting_start_blue_icon from "../Assets/Images/SVGs/blue_icon/IconSet-1.svg";
import Verif_sec_blue_icon from "../Assets/Images/SVGs/blue_icon/IconSet-2.svg";
import Investing_blue_icon from "../Assets/Images/SVGs/blue_icon/IconSet-3.svg";
import Auto_invest_blue_icon from "../Assets/Images/SVGs/blue_icon/IconSet-4.svg";
import Taxation_blue_icon from "../Assets/Images/SVGs/blue_icon/IconSet-5.svg";

export function validatePassword(password) {
  var re = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;
  return re.test(password);
}

export function camelCaseSting(string) {
  return string
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function getCountryNameByCode(countryList, code) {
  const country = countryList?.find((country) => country?.key === code);
  return country ? country?.value : null;
}

export function handleFinish(user, navigate) {
  if (user?.investorStatus === "ACTIVE") {
    redirectToVue();
  } else {
    if (
      checkStepStatus(user?.waitingVerificationSteps, "QUESTIONNAIRE") === false
    ) {
      if (user?.investorType === "INDIVIDUAL") {
        navigate(ROUTES.INDIVIDUAL_VERIFICATION);
      } else {
        navigate(ROUTES.ORGANIZATION_VERIFICATION);
      }
    } else {
      navigate(ROUTES.VERIFICATION);
    }
  }
}

export const getTransformedCountries = (countries) => {
  const transformed = countries?.map((country) => ({
    key: country.code,
    value: country.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All countries" });

  return transformed;
};

export const getTransformedIndustries = (industries) => {
  const transformed = industries?.map((industry) => ({
    key: industry.code,
    value: industry.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All industries" });

  return transformed;
};

export const getTransformedProductTypes = (products) => {
  const transformed = products?.map((product) => ({
    key: product.code,
    value: product.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All product type" });

  return transformed;
};

export const getTransformedLoanOriginator = (originators) => {
  const transformed = originators?.map((originator) => ({
    key: originator.code,
    value: originator.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All originators" });

  return transformed;
};

export const generateErrorMessages = (codes) => {
  return codes.map((code) => REGTANK_ERROR_MESSAGES[code]);
};

export const formatDateWithTime = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

export const britishFormatDateWithTime = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const isPM = hours >= 12;
  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, "0");

  const formattedDate = `${day} ${month} ${year} ${formattedHours}:${minutes} ${isPM ? "PM" : "AM"
    }`;

  return formattedDate;
};

export const SupportChatButton = () => {
  const getUser = Cookies.get("user");
  const user = getUser && JSON.parse(getUser);
  return (
    <div
      data-tf-popover="rSEPG1V2"
      data-tf-opacity="100"
      data-tf-iframe-props="title=Kilde Customer Support Chat"
      data-tf-transitive-search-params
      data-tf-button-color="#0445AF"
      data-tf-medium="snippet"
      data-tf-hidden={`email=${user?.email},investor_number=${user?.number}`}
      style={{ all: "unset" }}
    />
  );
};

export const stepperRedirection = (user) => {
  if (
    user?.vwoFeatures?.identityVerificationSystem?.idvSystemToUse ===
    "veriff" &&
    checkStepStatus(user?.waitingVerificationSteps, "IDENTITY_VERIFICATION") ===
    false
  ) {
    return 3;
  } else {
    return 1;
  }
};

export const formatCamelCaseToTitle = (str) => {
  const words = str?.replace(/([A-Z])/g, " $1").trim();
  return words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatCurrency = (currencySymbol, value) => {
  const formatter = new Intl.NumberFormat("en-SG", {
    style: "currency",
    currency: "SGD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  let formattedValue = formatter.format(value).replace("$", "").trim();
  if (value < 0) {
    formattedValue = `-${currencySymbol}${formattedValue.slice(1)}`;
  } else {
    formattedValue = `${currencySymbol}${formattedValue}`;
  }

  if (formattedValue.includes(".") && !formattedValue.includes("..")) {
    const decimalIndex = formattedValue.indexOf(".");
    const decimalPart = formattedValue.slice(decimalIndex + 1);
    if (decimalPart.length === 1) {
      formattedValue += "0";
    }
  }

  return formattedValue;
};

export const getFilenameDetails = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  const fileFormat =
    lastDotIndex !== -1 ? filename.substring(lastDotIndex) : "";

  const baseName =
    lastDotIndex !== -1 ? filename.substring(0, lastDotIndex) : filename;

  if (baseName.length > 20) {
    return `${baseName.substring(0, 20)}...${fileFormat}`;
  } else {
    return filename;
  }
};

export const resetJivoChat = () => {
  let clearHistory = () => {
    if (window.jivo_api) {
      window.jivo_api.clearHistory();
      console.log("Client data is deleted");
    } else {
      console.log("jivo_api is not available");
    }
  };

  clearHistory();
};

export const showMessageWithCloseIcon = (text) => {
  notification.success({
    description: text,
    showProgress: true,
  });
};

export const showMessageWithCloseIconError = (text) => {
  notification.error({
    description: text,
    showProgress: true,
    onClose: () => {
      console.log("Notification closed");
    },
  });
};

export const formatString = (input) => {
  return input
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function isNonDBSSingaporeBank(swiftCode) {
  const bankCode = swiftCode?.substring(0, 4);
  const countryCode = swiftCode?.substring(4, 6);
  if (countryCode === "SG" && bankCode !== "DBSS") {
    return true;
  }
  return false;
}

export const notificationMessageCorrection = (message) => {
  const messageMap = {
    "Onbarding step PERSONAL_DETAILS: COMPLETED":
      "Your personal details has been submitted successfully.",
    "Onbarding step QUESTIONNAIRE: COMPLETED":
      "Your investor type has been updated successfully.",
    "Onbarding step PROOF_OF_ACCREDITATION: COMPLETED":
      "Your proof of accreditation has been submitted successfully.",
    "Onbarding step DOCUMENTS: COMPLETED":
      "Your proof of address has been submitted successfully",
    "Onbarding step SECOND_FACTOR_AUTHENTICATION: COMPLETED":
      "Second factor authentication has been set up successfully.",
  };
  return messageMap[message] || message;
};

export const Timestamp = (timestamp) => {
  const singaporeTime = new Date(timestamp);
  const utcTime = new Date(singaporeTime.getTime() - 8 * 60 * 60 * 1000); // Singapore is UTC+8
  const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const localTime = new Date(utcTime.getTime() - localOffset);
  const formattedTime = formatDistanceToNow(localTime, { addSuffix: true });

  return formattedTime;
};

export const generalInformation = [
  {
    header: "What is KILDE?",
    description:
      "KILDE is a global private debt marketplace where alternative lending firms (Non-Banking Financial Institutions) raise debt capital with institutional and accredited investors. KILDE assists the participants throughout the entire process of arranging the debt issuance in the form of debt securities, private placement of the securities, subscription, settlement, and loan monitoring.",
  },
  {
    header: "How does it work?",
    description:
      "KILDE arranges investments in alternative debt through debt securities (debentures). Alternative lenders issue debentures, which are offered to a pool of investors at the platform, within the credit limit set by KILDE. The platform automates the entire process of issuance, subscription and settlement of the debentures and assists the participants throughout the entire investment cycle. Each alternative lender has an extensive information profile at the platform.Moreover, each debenture issuance is supplemented with Information Memorandum and Pricing Supplement. The investors and the alternative lender issuing the debentures sign the sales agreement digitally in the platform. Proceeds from the sales of the debentures are transferred weekly to the escrow account of the participants. Funds are held in a segregated account controlled by a third-party escrow agent, which is responsible for managing payments and registering the debentures holders.",
  },
  {
    header: "Why is KILDE different?",
    description: `
  <p>
    We built the platform willing to differentiate our solution from what is
    currently available in the market.
  </p>
  <ul>
    <li>
      Investors do not bear the credit risk of individual loans. The
      borrower is obliged to repay the principal and interest to the
      investors, irrespective of the performance of the underlying loans;
    </li>
    <li>
      Investors have access to a convenient marketplace to diversify their
      investment portfolio. The credit risk of the underlying loans is
      spread among a handful of alternative lenders with whom we manage
      the risks through continuous credit assessment;
    </li>
    <li>
      Alternative lenders can find additional sources of funding to
      increase their loan portfolio and provide cheaper and less
      restrictive loans to their customers.
    </li>
  </ul>
`,
  },
  {
    header: "Is KILDE licensed?",
    description:
      "KILDE PTE LTD is incorporated in Singapore under the registration no. 201929587K and holds a Capital Markets Services license (CMS101016) issued by the Monetary Authority of Singapore to deal in capital markets products under the Securities and Futures Act (Cap. 289).",
  },
  {
    header: "What happens if KILDE decides to terminate its operations?",
    description:
      "In case of such an unlikely event, KILDE will stop accepting new funds from investors and will cancel the issuance of new debentures. The platform will operate until all the active debentures are liquidated in the system, which means that the loan originators must keep following the payment schedule of those debentures so that investors can be paid accordingly to the terms of their ongoing positions.",
  },
];

export const gettingStarted = [
  {
    header: "Who can join?",
    description: `At the moment, we can only onboard accredited and institutional investors, but we are working towards making our deals accessible to the wider investor base. You can 
      <a
        href="/signup"
        style="color: var(--kilde-blue); text-decoration: underline;"
      >
        sign up
      </a>, 
      and we will notify you once this becomes possible.
      <p>
        Once registered, you will be asked to fill out the questionnaire and provide documents to confirm your status of "accredited" or "institutional" investor.
      </p>
      <ul>
        <li>
          Annual income: your net income shall be exceeding SGD 300,000 (ca. USD 224,000) in the past 12 months OR
        </li>
        <li>
          Financial assets: your financial assets should be equal or exceed SGD 1,000,000 (ca. USD 745,000) at the time of registration OR
        </li>
        <li>
          Personal assets: your personal assets should be equal or exceed SGD 2,000,000 (ca. USD 1,490,000) at the time of registration.
        </li>
      </ul>
      If you meet any of the three criteria, congratulations, you are an accredited investor according to Singaporean regulations.
      <br />
      If you have doubts whether you would qualify, please do not hesitate to contact our investor relations team at 
      <a
        href="mailto:info@kilde.sg"
        style="color: var(--kilde-blue); text-decoration: underline;"
      >
        info@kilde.sg
      </a> 
      or 
      <a
        href="mailto:aleksandra@kilde.sg"
        style="color: var(--kilde-blue); text-decoration: underline;"
      >
        aleksandra@kilde.sg
      </a>.
      <br />
      If you represent a financial institution or a corporation, please 
      <a
        href="/register"
        style="color: var(--kilde-blue); text-decoration: underline;"
      >
        register as a legal entity
      </a>.
    `,
  },
  {
    header: "What documents should I provide as an investor?",
    description: `
      If you are an accredited investor be ready to provide:
    <ul>
      <li>Passport or ID Card</li>
      <li>
        Proof of wealth or income:
        <ul>
          <li>Pay slips / tax declaration</li>
          <li>Bank statement</li>
          <li>
            Investment statements (Capital Market Products, Bullion, Stock,
            Cryptocurrency holdings)
          </li>
          <li>Investment life policy statement</li>
          <li>Life policies certificate</li>
          <li>
            Financial statements and business registry excerpt to confirm
            the value of your shares in private companies
          </li>
          <li>Excerpt from land / property registry</li>
        </ul>
      </li>
      <li>
        Proof of address (document should not be older than 3 months):
        <ul>
          <li>Copy of a bill (e.g. utility, mobile, internet, etc.)</li>
          <li>Bank statement with address</li>
          <li>Tenant agreement</li>
        </ul>
      </li>
    </ul>
  `,
  },
  {
    header: "How an investor transfer money to her account at the platform?",
    description:
      "KILDE works with a third-party escrow agent licensed by the Monetary Authority of Singapore (MAS) to manage all money transactions. The funds are held in secure segregated escrow account controlled by the licensed trust R&H Trust Co. (Singapore) Pte Ltd and operated by Vistra Singapore. We will share the account details with the investor once the onboarding process is completed.",
  },

  {
    header: "How can I withdraw my money?",
    description:
      "Investors can request partial or full withdrawal of their available funds at any time by using the dashboard at the KILDE’s platform. Please note that actual payment transfer may take a few workdays to reach your registered external account depending on your country.",
  },
  {
    header:
      "Which currency should I consider when transferring or getting the funds?",
    description:
      "Although most of the bonds placed on our platform are USD - denominated, we can accept deposits in USD, SGD (Singapore Dollar) and EUR. The funds transferred in SGD and EUR will be converted to USD (for transactions in USD), using DBS bank treasury rate.",
  },
];

export const verificationAndSecurity = [
  {
    header: "Who can join?",
    description: `To invest in the platform you must complete the onboarding process and
        have available funds in the escrow account. You can choose to invest
        either by manually selecting available debentures or leaving the
        investment to our automatic matching mechanism. Tranches of debentures
        will be offered regularly at our platform and you must purchase the
        debentures during the subscription period. Manual investment: You can
        select which debentures you would like to purchase. You can compare
        debenture details such as term, interest rate, and alternative lender
        information to determine which debentures you prefer. Automated
        investment: Relying on the Auto-Invest tool, you can define criteria
        that allow the system to automatically purchase the debentures matching
        those determined criteria.`,
  },
];

export const investing = [
  {
    header: "How can I invest?",
    description: `To invest in the platform you must complete the onboarding process and
    have available funds in the escrow account. You can choose to invest
    either by manually selecting available debentures or leaving the
    investment to our automatic matching mechanism. Tranches of debentures
    will be offered regularly at our platform and you must purchase the
    debentures during the subscription period. <br />
    <ul>
      <li>
        Manual investment: You can select which debentures you would like to
        purchase. You can compare debenture details such as term, interest
        rate, and alternative lender information to determine which
        debentures you prefer.
      </li>
      <li>
        Automated investment: Relying on the Auto-Invest tool, you can
        define criteria that allow the system to automatically purchase the
        debentures matching those determined criteria.
      </li>
    </ul>
  `,
  },
  {
    header: "What are the terms of the debentures offered at the platform?",
    description:
      "Debentures issued at the platform have flexible terms (from 3 to 36 months) with interest being paid on a monthly or another basis. The interest rate reflects the maturity and risks associated with the debenture and is currently ranging from 9.0% to 15.0% p.a.",
  },
  {
    header: "How is interest calculated?",
    description:
      "All interest rates are expressed as annual figures. Interest is calculated based on the amount that you have invested to purchase the debenture on a respective day. The formula for calculating interest is as follows: Invested amount * Period of holding the debenture (Days) * Interest rate (%) p.a. / 365.",
  },
  {
    header: "How often investors will get paid?",
    description:
      "Repayments to investors are scheduled to occur according to the terms of the particular debenture. Please always check the payment schedule of each transaction to determine the exact dates of the repayment. The generated income can be reinvested or withdrawn. All transactions can be reviewed at the KILDE’s platform at any time.",
  },
  {
    header: "Can I cancel an ongoing investment?",
    description:
      "The investor is allowed to cancel the subscription request before the funds' settlement which occurs once a week. However, once the purchase of the debenture is settled, the investor can not cancel the investment transaction.",
  },
  {
    header: "What is the minimum amount that can be invested in the platform?",
    description:
      "The platform works based on pre-funded wallets. Investors can have access to the debentures through a minimal investment of $100.00.",
  },
  {
    header: "What fees are applicable?",
    description:
      "KILDE charges investors a fee of 0.5% p.a. on investment outstanding. For institutional investors, who invest above $5,000,000 the fee is waived. Also, we have a progressive fee revenue sharing scheme with investors in place. Please, get in touch with our sales team to find out more.",
  },
  {
    header: 'How long is the invested fund "locked-in"?',
    description:
      "Your funds deposited at the escrow account are available to you anytime as long as you have not committed them to a debenture purchase. Once you have committed to subscribe to a debenture, funds allocated for this purchase have been “locked” until the funds' settlement. Please, see the answer to question “3.5. Can I cancel an ongoing investment?” for more details. From the day of funds settlement, you will receive interest and repayment of the principal as per terms of the debenture. The interest income is paid regularly and these proceeds can be reinvested at the platform or withdrawn to back to your bank account.",
  },
  {
    header: "How can investors monitor their activities?",
    description:
      "KILDE has a user-friendly dashboard that allows investors to check their account statements and information related to the debentures being held.",
  },
  {
    header: "What happens if an alternative lender goes out of business?",
    description:
      "It is KILDE’s priority to provide a good selection of alternative lenders, which must fulfil a stern onboarding and credit risk assessment process. However, you should be aware that by investing through Kilde’s platform your capital is at risk. To mitigate unpleasant surprises, our team manages the risks through continuous credit assessment and loan covenants monitoring. Furthermore, most of the deals are secured by the pledge of the loan portfolio. Please, see details about the specific debenture at the platform.",
  },
];

export const autoInvest = [
  {
    header: "What is Auto-Invest?",
    description:
      "Auto-Invest is an advanced tool that helps investors to save time and ensure better diversification by purchasing multiple debenture tranches accordingly to a pre-determined set of criteria. Investors can create an investment portfolio profile beforehand by selecting the desirable terms, interest rates, loan originators and countries so that the system can make future purchases based on those criteria.",
  },
  {
    header: "How does Auto-Invest work?",
    description: `First, you must activate your Auto-Invest portfolio and allocate funds
  to this portfolio. The system will automatically filter among all the
  debentures listed at the platform and purchase those that meet your
  investment criteria. Every time a new debenture is issued, the system
  will evaluate if it is eligible to purchase based on your defined
  criteria.
  <br />
  This feature is programmed to make the best of the allocated funds to
  make them work most of the time. In case there are not enough debentures
  at the platform meeting the specified criteria, the funds will remain
  allocated in your account.
`,
  },
  {
    header: "How to create my Auto-Invest portfolio?",
    description:
      "It is simple, just go to the Auto-Invest section to determine the portfolio size, diversification weight and the criteria such as terms, interest rates, loan originators and countries to be considered by the system when purchasing a debenture.",
  },
  {
    header: "Can I stop Auto-Invest?",
    description:
      "You can immediately edit, deactivate and delete portfolios at any time by visiting the Auto-Invest settings. The already purchase debentures will remain at your account until their maturity.",
  },
  {
    header: "Can I use Auto-Invest and invest manually?",
    description:
      "Yes, you can still invest your funds manually while using the Auto-Invest tool as long as you have enough available funds.",
  },
  {
    header: "Are there any extra costs to use Auto-Invest?",
    description:
      "No, this is a free convenient tool provided by the platform to promote diversification and save time for the investors.",
  },
];

export const taxation = [
  {
    header: "What is Withholding Tax (WHT)?",
    description: `
  Withholding tax refers to the tax that is withheld or deducted at source
  on certain types of payments made to non-residents.
  <br />
  In Singapore, payer entities are required by law to withhold or deduct a
  percentage of payments made to non-resident recipients and remit this
  directly to the Inland Revenue Authority of Singapore (IRAS).
  <br />
  <ul>
    <li>
      Some examples of payments that may be subject to WHT in Singapore
      include:
      <ul>
        <li>Interest payments</li>
      </ul>
    </li>
  </ul>
  The rate of WHT may vary depending on factors like the recipient's
  country of residence and whether a tax treaty is in place between
  Singapore and that country. WHT aims to ensure that non-residents pay
  tax on Singapore-sourced income.
  <br />
  In summary, WHT is tax deducted on payments to non-residents which the
  local payer remits directly to the tax authority (IRAS). It prevents the
  avoidance of tax obligations by non-residents.
`,
  },
  {
    header: "Does the Withholding Tax (WHT) apply to Singaporeans?",
    description:
      "No, WHT does not apply to payments made to Singapore tax residents. It only applies to payments made to non-residents.",
  },
  {
    header: "As a tax resident outside of Singapore, am I required to pay WHT?",
    description:
      "Yes, if you receive coupon payment from an issuer in Singapore, WHT will be applied. Kilde is responsible for deducting the WHT and paying it to the IRAS.",
  },
  {
    header: "How do I pay the WHT?",
    description:
      "As a non-resident, you do not have to pay the WHT yourself. We are responsible for deducting the appropriate WHT rate and paying it to IRAS on your behalf. The WHT will be deducted from the interest payment you receive.",
  },
  {
    header: "Is the WHT applicable for all deals on Kilde platform?",
    description: `
  <p>
    No, withholding tax (WHT) is not applicable for all deals on the Kilde
    platform. WHT only applies to certain deals that meet specific
    criteria:
  </p>
  <ul>
    <li>The issuer must be based in Singapore</li>
    <li>
      Or the debentures must be issued from a Singapore-based Special
      Purpose Vehicle (SPV)
    </li>
  </ul>
  <p>
    Deals meeting either of these criteria will be marked as attracting
    WHT in the deal summary on the Kilde platform.
  </p>
  <p>
    For deals where the issuer is not based in Singapore and the
    debentures are not issued from a Singapore-based SPV, WHT will not
    apply.
  </p>
  <p>
    So, in summary, WHT only applies to select deals on the Kilde
    platform, specifically those marked as attracting WHT. It is not
    applicable across all deals on the platform. The deal summary
    indicates which deals have WHT implications.
  </p>
`,
  },
  {
    header: "How do I know how much is the WHT?",
    description: `
  <p>
    No, withholding tax (WHT) is not applicable for all deals on the Kilde
    platform. WHT only applies to certain deals that meet specific
    criteria:
  </p>
  <ul>
    <li>The issuer must be based in Singapore</li>
    <li>
      Or the debentures must be issued from a Singapore-based Special
      Purpose Vehicle (SPV)
    </li>
  </ul>
  <p>
    Deals meeting either of these criteria will be marked as attracting
    WHT in the deal summary on the Kilde platform.
  </p>
  <p>
    For deals where the issuer is not based in Singapore and the
    debentures are not issued from a Singapore-based SPV, WHT will not
    apply.
  </p>
  <p>
    So, in summary, WHT only applies to select deals on the Kilde
    platform, specifically those marked as attracting WHT. It is not
    applicable across all deals on the platform. The deal summary
    indicates which deals have WHT implications.
  </p>
`,
  },
];

export const Icons = {
  gray: {
    General: General_icon,
    GettingStarted: Getting_start_icon,
    VerificationSecurity: Verif_sec_icon,
    Investing: Investing_icon,
    AutoInvest: Auto_invest_icon,
    Taxation: Taxation_icon,
  },
  blue: {
    General: General_blue_icon,
    GettingStarted: Getting_start_blue_icon,
    VerificationSecurity: Verif_sec_blue_icon,
    Investing: Investing_blue_icon,
    AutoInvest: Auto_invest_blue_icon,
    Taxation: Taxation_blue_icon,
  },
};

export const helpDeskItems = [
  {
    value: 1,
    label: "General Information",
    activeIcon: General_blue_icon,
    defaultIcon: General_icon,
  },
  {
    value: 2,
    label: "Getting Started",
    activeIcon: Getting_start_blue_icon,
    defaultIcon: Getting_start_icon,
  },
  // {
  //   value: 3,
  //   label: "Verification and Security",
  //   activeIcon: Verif_sec_blue_icon,
  //   defaultIcon: Verif_sec_icon,
  // },
  {
    value: 3,
    label: "Investing",
    activeIcon: Investing_blue_icon,
    defaultIcon: Investing_icon,
  },
  {
    value: 4,
    label: "Auto-Invest",
    activeIcon: Auto_invest_blue_icon,
    defaultIcon: Auto_invest_icon,
  },
  {
    value: 5,
    label: "Taxation",
    activeIcon: Taxation_blue_icon,
    defaultIcon: Taxation_icon,
  },
];

export const formatAccountNumberwithStar = (accountNumber) => {
  const maskedPart = "*".repeat(accountNumber.length - 4);
  const lastFourDigits = accountNumber.slice(-4);
  const fullMaskedNumber = maskedPart + lastFourDigits;
  const formattedNumber = fullMaskedNumber.replace(/(.{4})(?=.)/g, "$1 ");
  return (
    <span>
      {formattedNumber.split(" ").map((group, index) => (
        <span
          key={index}
          style={{
            marginRight: "8px",
            marginTop: "5px",
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          {group}
        </span>
      ))}
    </span>
  );
};

export const formatAccountNumber = (accountNumber) => {
  const formattedNumber = accountNumber.replace(/(\d{4})(?=\d)/g, "$1 ");

  return (
    <span>
      {formattedNumber.split(" ").map((group, index) => (
        <span
          key={index}
          style={{
            marginRight: "8px",
            marginTop: "5px",
            display: "inline-block",
            wordBreak: "break-word",
          }}
        >
          {group}
        </span>
      ))}
    </span>
  );
};

export const highlightMatch = (text, searchValue) => {
  if (!searchValue) return text;

  const regex = new RegExp(`(${searchValue})`, "gi");
  const container = document.createElement("div");
  container.innerHTML = text;

  const anchors = container.querySelectorAll("a");
  anchors.forEach((anchor) => {
    anchor.removeAttribute("style");

    const span = anchor.querySelector("span");
    if (span) {
      const textBeforeSpan = anchor.childNodes;
      for (let i = 0; i < textBeforeSpan.length; i++) {
        if (textBeforeSpan[i].nodeType === Node.TEXT_NODE) {
          anchor.removeChild(textBeforeSpan[i]);
          break;
        }
      }
    }
  });

  container.innerHTML = container.innerHTML.replace(regex, (match) => {
    return `<span class="highlight">${match}</span>`;
  });
  return container.innerHTML;
};

export const bankOptions = [
  "DBS Bank",
  "OCBC Bank",
  "UOB Bank",
  "Bank of Singapore",
  "Citibank Singapore",
  "HSBC Bank",
  "Standard Chartered",
  "Maybank",
  "BNP Paribas",
  "RHB Singapore",
  "Bank of China Limited",
  "CIMB Bank Berhad",
  "Deutsche Bank AG",
];

export const bankSwiftCodes = {
  "DBS Bank": "DBSSSGSG",
  "OCBC Bank": "OCBCSGSG",
  "UOB Bank": "UOVBSGSG",
  "Bank of Singapore": "INGPSGSG",
  "Citibank Singapore": "CITISGSG",
  "HSBC Bank": "HSBCSGSG",
  "Standard Chartered": "SCBLSG22",
  Maybank: "MBBESGSG",
  "BNP Paribas": "BNPASGSG",
  "RHB Singapore": "RHBBSGSG",
  "Bank of China Limited": "BKCHSGSG",
  "CIMB Bank Berhad": "CIBBSGSG",
  "Deutsche Bank AG": "DEUTSGSG",
};

export function clearAllCookiesForDomain(domain) {
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim();
    document.cookie = `${cookieName}=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  });
}

export function clearUserSession() {
  localStorage.removeItem("recordTag");
  localStorage.clear();
  const cookieNames = [
    "sid",
    "auth_inv_token",
    "userUid",
    "singpass_config",
    "singpass_code",
    "XSRF-TOKEN",
    "verificationToken",
    "systemId",
    "user",
  ];

  cookieNames.forEach((cookie) => {
    Cookies.remove(cookie);
  });

  if (typeof resetJivoChat === "function") {
    resetJivoChat();
  }
}
