/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Modal,
  Select,
  Space,
} from "antd";

import Cookies from "js-cookie";

import ROUTES from "../../Config/Routes";
import Footer from "../BlankHeaderLayout/Footer";

import { useDispatch, useSelector } from "react-redux";

import Book_Icon from "../../Assets/Images/book_icon.svg";
import Logo from "../../Assets/Images/logo.svg";
import User_frame from "../../Assets/Images/Icons/Dashboard/user_frame.svg";
import Logout_red_icon from "../../Assets/Images/Icons/logout_red_icon.svg";
import Toggle_Icon from "../../Assets/Images/Icons/Dashboard/toggle_icon.svg";
import Wallet_Icon from "../../Assets/Images/Icons/Dashboard/wallet_icon.svg";
import Down_arrow from "../../Assets/Images/Icons/Dashboard/down_arrow.svg";
import Profile_setting_icon from "../../Assets/Images/profile_setting_icon.svg";
import Onboarding from "../../Assets/Images/Icons/onboarding.svg";
import Dashboard from "../../Assets/Images/Icons/dashboard.svg";
import Invest from "../../Assets/Images/Icons/invest.svg";
import AccountStatement from "../../Assets/Images/Icons/accountStatement.svg";
import wallet from "../../Assets/Images/Icons/wallet.svg";
import MenuCloseIcon from "../../Assets/Images/menu-close.svg";
import icontwofa from "../../Assets/Images/IconSettwofa.svg";
import "./style.css";
import { checkStepStatus } from "../../Utils/Helpers";
import { clearAllCookiesForDomain, clearUserSession } from "../../Utils/Reusables";
import ProductTour from "../../Pages/ProductTour/ProductTour";
import Notification from "./Notification";
import { GetBankAccountApi } from "../../Apis/WalletApi";
import { setAccountDetails } from "../../Redux/Action/Wallet";
import { setUserDetails } from "../../Redux/Action/User";
import { getUser } from "../../Apis/UserApi";

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const token = Cookies.get("auth_inv_token");
  const location = useLocation();
  const pathname = location.pathname;
  const firstSlashIndex = pathname.indexOf("/");
  const routeName = pathname.substring(firstSlashIndex + 1);
  const [currencyVal, setCurrencyVal] = useState();
  const userRedux = useSelector((state) => state.user);
  const getUserDetail = Cookies.get("user");
  const stringyUser =
    getUserDetail === undefined ? null : JSON.parse(getUserDetail);
  const user = Object.keys(userRedux).length !== 0 ? userRedux : stringyUser;
  const currency = useSelector(
    (state) => state?.dashboards?.DashboardData?.currencyCode
  );

  const accounts = useSelector((state) => state?.wallet?.bankAccount);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };
    if (
      user?.hasSeenProductTour === false &&
      user?.productTourEnabled === true
    ) {
      if (window.innerWidth < 768) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setCurrencyValue = () => {
    const findCurrencyAccount = (accounts, currency) => {
      return accounts.find((account) => account.currencyCode === currency);
    };

    if (userRedux && Object.keys(userRedux).length !== 0) {
      const userReduxAccount = findCurrencyAccount(
        userRedux.accounts,
        currency
      );
      if (userReduxAccount) {
        setCurrencyVal(
          `${
            userReduxAccount.currencySymbol
          } ${userReduxAccount.balance.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        );
      }
    } else if (user?.accounts?.length > 0) {
      const userAccount = findCurrencyAccount(user.accounts, currency);
      if (userAccount) {
        setCurrencyVal(
          `${userAccount.currencySymbol} ${userAccount.balance.toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`
        );
      }
    } else {
      setCurrencyVal(null);
    }
  };

  useEffect(() => {
    setCurrencyValue();
  }, [userRedux, user, currency]);

  useEffect(() => {
    if (
      (Array.isArray(accounts) && accounts.length === 0) ||
      (typeof accounts === "object" &&
        accounts !== null &&
        Object.keys(accounts).length === 0)
    ) {
      getBankAccountNo();
    }
  }, [accounts]);

  const getBankAccountNo = async () => {
    try {
      const response = await GetBankAccountApi();
      if (response) {
        await setAccountDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleChange = (value) => {
    setCurrencyVal(value);
  };

  const showDrawer = () => {
    setUserDrawerOpen(false);
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showUserDrawer = () => {
    setOpen(false);
    setUserDrawerOpen(!userDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      clearAllCookiesForDomain(".kilde.sg");
      clearUserSession();
      window.location.href = ROUTES.LOGIN;
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const items = [];

  if (user?.secondFactorAuth === null) {
    items.push({
      label: (
        <div className="twofa-div">
          <div style={{ display: "flex" }}>
            <img src={icontwofa} alt="twofa" style={{ marginRight: "8px" }} />
            <p className="twafa-p m-0">
              Your account security is low. Please set up two-factor
              authentication now.
            </p>
          </div>
          <Button
            onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
            className="button-twofa"
          >
            Set Up 2FA Now
          </Button>
        </div>
      ),
      key: "0",
    });
  }

  items.push(
    {
      label: (
        <div className="sb-justify-center-item-center">
          <p className="mt-0 mb-4 user-name-dropdown p-capitalize">
            {" "}
            {user?.firstName + " " + user?.lastName}
          </p>
          <Button
            className={` investor-status-button ${
              user?.investorStatus === "ACTIVE"
                ? "investor-status-button-active"
                : user?.verificationState === "MANUAL_REVIEW"
                ? "investor-status-button-under-review"
                : "investor-status-button-notactive"
            }`}
          >
            {" "}
            {user?.investorStatus === "ACTIVE"
              ? "Verified"
              : user?.verificationState === "MANUAL_REVIEW"
              ? "Under review"
              : "Not verified"}
          </Button>
        </div>
      ),
      className: "user-dropdown-list",
      key: "1",
    },
    {
      label: (
        <Link to={ROUTES.SETTINGS} className="user-dropdown-link">
          <img
            src={Profile_setting_icon}
            alt="setting_icon"
            style={{ marginRight: "4px" }}
          />
          Personal Settings
        </Link>
      ),
      key: "2",
    },

    {
      label: (
        <div onClick={handleLogout} className="logout-div">
          <img src={Logout_red_icon} alt="logout-img" /> Logout
        </div>
      ),
      key: "3",
    }
  );

  const condition =
    checkStepStatus(user?.waitingVerificationSteps, "QUESTIONNAIRE") ===
      false &&
    (user?.verificationState === "" ||
      user?.verificationState === null ||
      user?.verificationState === "WAITING_INVESTOR_DATA" ||
      user?.verificationState === "MANUAL_REVIEW") &&
    user?.investorStatus !== "ACTIVE";

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      return null || error;
    }
  };

  const handleDropdownVisibleChange = async (open) => {
    if (open) {
      await getUserDetails();
    }
  };

  return (
    <div className="p-relative">
      <div className="dashboard-layout-div">
        <div
          className="kilde-dashboard-header"
          style={{
            backgroundColor: userDrawerOpen ? "#F8F7F2" : "#ffffff",
          }}
        >
          <div className="dashboard-main-logo">
            <div>
              <Link
                to={
                  user?.verificationState === "MANUAL_REVIEW" ||
                  user?.verificationState === "COMPLETED"
                    ? ROUTES.DASHBOARD
                    : user?.investorType === "COMPANY"
                    ? ROUTES.ORGANIZATION_VERIFICATION
                    : ROUTES.INDIVIDUAL_VERIFICATION
                }
              >
                <img src={Logo} alt="logo" className="sb-logo" />
              </Link>
            </div>
          </div>
          <div className="dashboard-link-div">
            <div className="dashboard-header-link">
              {condition && (
                <Col className="with-border">
                  <Link
                    ref={ref1}
                    to={
                      user?.investorType === "INDIVIDUAL"
                        ? ROUTES.INDIVIDUAL_VERIFICATION
                        : ROUTES.ORGANIZATION_VERIFICATION
                    }
                    className={
                      "/" + routeName === ROUTES.INDIVIDUAL_VERIFICATION ||
                      "/" + routeName === ROUTES.VERIFICATION ||
                      "/" + routeName === ROUTES.ORGANIZATION_VERIFICATION
                        ? "title-active"
                        : null
                    }
                  >
                    Onboarding
                  </Link>
                </Col>
              )}

              <Col style={{ paddingLeft: condition ? 20 : 0 }}>
                <Link
                  ref={ref4}
                  to={ROUTES.DASHBOARD}
                  className={routeName === "dashboard" ? "title-active" : null}
                >
                  Dashboard
                </Link>
              </Col>
              <Col>
                <Link
                  ref={ref3}
                  className={
                    routeName === "tranche-listing" ? "title-active" : null
                  }
                  to={ROUTES.TRANCH_LISTING}
                >
                  Investments
                </Link>
              </Col>
              <Col>
                <Link
                  ref={ref5}
                  className={
                    routeName === "account-statement" ? "title-active" : null
                  }
                  to={ROUTES.ACCOUNT_STATEMENT}
                >
                  Account Statement
                </Link>
              </Col>
              <Col>
                <Link
                  ref={ref2}
                  className={routeName === "wallet" ? "title-active" : null}
                  to={ROUTES.WALLET}
                >
                  Wallet
                </Link>
              </Col>
            </div>
            {token &&
              token !== "undefined" &&
              window.location.pathname !== ROUTES.LOGIN &&
              window.location.pathname !== ROUTES.REGISTER &&
              window.location.pathname !== ROUTES.SINGPASS_REGISTER &&
              window.location.pathname !== ROUTES.TWO_FA && (
                <div className="dashboard-logo">
                  <div
                    className="amount-selectBox"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={Wallet_Icon}
                      alt="wallet_icon"
                      className="wallet-img"
                    />
                    <Select
                      suffixIcon={<img src={Down_arrow} alt="arr" />}
                      value={currencyVal}
                      style={{
                        width: 120,
                      }}
                      onDropdownVisibleChange={handleDropdownVisibleChange}
                      onChange={handleChange}
                      options={
                        user?.accounts?.length > 0 &&
                        user?.accounts?.map((currency) => ({
                          value: `${
                            currency.currencySymbol
                          } ${currency.balance?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`,
                          label: `${
                            currency.currencySymbol
                          } ${currency.balance?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`,
                        }))
                      }
                    />
                  </div>
                  <div
                    style={{ color: "#1a202c" }}
                    className="cursor-pointer"
                    onClick={() => navigate(ROUTES.HELP_DESK)}
                  >
                    <img src={Book_Icon} alt="book_icon" />
                  </div>
                  {isWideScreen ? (
                    <div className="desktop-notication-img">
                      <Notification />
                    </div>
                  ) : null}

                  <div
                    style={{ color: "#1a202c" }}
                    className="cursor-pointer hide-user-img"
                  >
                    <Button
                      className={
                        userDrawerOpen ? "user-btn p-0" : "user-normal-btn p-0"
                      }
                      onClick={showUserDrawer}
                    >
                      <img src={User_frame} alt="user_icon" />
                    </Button>
                  </div>
                  {!isWideScreen ? (
                    <div className="cursor-pointer notificatioon-img">
                      <Notification />
                    </div>
                  ) : null}

                  <div className="dashbard-blankheader-actions" ref={ref6}>
                    <Dropdown
                      suffixIcon={<img src={Down_arrow} alt="arr" />}
                      menu={{
                        items,
                        className: "user-dropdown",
                      }}
                      trigger={["click"]}
                      className="p-relative"
                    >
                      <div onClick={(e) => e.preventDefault()}>
                        <Space>
                          <p className="cursor-pointer p-capitalize m-0 fw-600">
                            {user?.firstName + " " + user?.lastName}
                          </p>
                          <div className="p-absolute dropdown-header-arrow ">
                            <img
                              src={Down_arrow}
                              alt="arr"
                              className="mb-8 ml-4"
                            />
                          </div>
                        </Space>
                        <p className="mb-0 mt-5 user-id-tag cursor-pointer">
                          {`User ID: ${user?.number}`}{" "}
                        </p>
                      </div>
                    </Dropdown>
                  </div>
                  <div className="dashbard-blankheader-toggle cursor-pointer">
                    <Button onClick={showDrawer} className="toggle-btn">
                      <img src={Toggle_Icon} alt="toggle_icon" />
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div>{children}</div>
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        key="right-drawer"
        className="drawer tab-drawer"
        style={{ padding: "0px 20px" }}
      >
        <div
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="dashboard-main-logo">
            <div>
              <Link to="https://kilde.sg/">
                <img src={Logo} alt="logo" className="sb-logo" />
              </Link>
            </div>
          </div>
          <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
            <div
              style={{ color: "#1a202c" }}
              className="cursor-pointer hide-user-img"
              ref={ref12}
            >
              <Button
                className={
                  userDrawerOpen ? "user-btn p-0" : "user-normal-btn p-0"
                }
                onClick={showUserDrawer}
              >
                <img src={User_frame} alt="user_icon" />
              </Button>
            </div>

            <div
              style={{ color: "#1a202c" }}
              className="cursor-pointer hide-user-img"
            >
              <Button
                className={
                  userDrawerOpen ? "user-btn p-0" : "user-normal-btn p-0"
                }
                onClick={onClose}
              >
                <img src={MenuCloseIcon} alt="user_icon" />
              </Button>
            </div>
          </div>
        </div>
        {condition && (
          <div className="mb-20" style={{ marginTop: 56 }}>
            <Link
              to={
                user?.investorType === "INDIVIDUAL"
                  ? ROUTES.INDIVIDUAL_VERIFICATION
                  : ROUTES.ORGANIZATION_VERIFICATION
              }
              className={
                "/" + routeName === ROUTES.INDIVIDUAL_VERIFICATION ||
                "/" + routeName === ROUTES.VERIFICATION ||
                "/" + routeName === ROUTES.ORGANIZATION_VERIFICATION
                  ? "title-active"
                  : null
              }
              ref={ref7}
            >
              <img src={Onboarding} alt="onboarding" /> <span>Onboarding</span>
            </Link>
          </div>
        )}
        <div className="drawer-divider-div">
          <Divider plain />
        </div>
        <div className="mb-20">
          <Link
            to={ROUTES.DASHBOARD}
            className={routeName === "dashboard" ? "title-active" : null}
            ref={ref10}
          >
            <img src={Dashboard} alt="dashboard" /> <span>Dashboard</span>
          </Link>
        </div>
        <div className="mb-20">
          <Link
            className={routeName === "tranche-listing" ? "title-active" : null}
            to={ROUTES.TRANCH_LISTING}
            ref={ref9}
          >
            <img src={Invest} alt="invest" /> <span>Investments</span>
          </Link>
        </div>
        <div className="mb-20">
          <Link
            className={
              routeName === "account-statement" ? "title-active" : null
            }
            to={ROUTES.ACCOUNT_STATEMENT}
            ref={ref11}
          >
            <img src={AccountStatement} alt="accountStatement" />
            <span>Account Statement</span>
          </Link>
        </div>
        <div className="mb-20">
          <Link
            className={routeName === "wallet" ? "title-active" : null}
            to={ROUTES.WALLET}
            ref={ref8}
          >
            <img src={wallet} alt="wallet" /> <span>Wallet</span>
          </Link>
        </div>
      </Drawer>

      <Modal
        className="user-drawer-modal"
        open={userDrawerOpen}
        onCancel={() => setUserDrawerOpen(false)}
        maskClosable="true"
        closable="false"
        closeIcon={null}
        footer={null}
        width={340}
        maskStyle={{ backgroundColor: "transparent" }}
      >
        <div className="user-btn-div ">
          {user?.secondFactorAuth === null ? (
            <div className="twofa-div">
              <div style={{ display: "flex" }}>
                <img
                  src={icontwofa}
                  alt="twofa"
                  style={{ marginRight: "8px" }}
                />
                <p className="twafa-p m-0">
                  Your account security is low. Please set up two-factor
                  authentication now.
                </p>
              </div>
              <Button
                onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
                className="button-twofa"
              >
                Set Up 2FA Now
              </Button>
            </div>
          ) : null}

          <div style={{ marginBottom: "20px" }}>
            <div className="sb-justify-center-item-center">
              <p className="mt-0 mb-4 user-name-dropdown p-capitalize">
                {" "}
                {user?.firstName + " " + user?.lastName}
              </p>
              <Button
                className={` investor-status-button ${
                  user?.investorStatus === "ACTIVE"
                    ? "investor-status-button-active"
                    : user?.verificationState === "MANUAL_REVIEW"
                    ? "investor-status-button-under-review"
                    : "investor-status-button-notactive"
                }`}
              >
                {" "}
                {user?.investorStatus === "ACTIVE"
                  ? "Verified"
                  : user?.verificationState === "MANUAL_REVIEW"
                  ? "Under review"
                  : "Not verified"}
              </Button>
            </div>
            <p className="m-0 user-dropdown-name">{user?.email}</p>
            <p className="m-0 user-dropdown-name">
              {`User ID: ${user?.number}`}{" "}
            </p>
          </div>

          <div className="mt-20">
            <Link to={ROUTES.SETTINGS} className="profile-seeting-link">
              <img
                src={Profile_setting_icon}
                alt="setting_icon"
                style={{ marginRight: "4px" }}
              />
              Personal Settings
            </Link>
          </div>
          <div onClick={handleLogout} className="mt-20 logout-div">
            <img src={Logout_red_icon} alt="logout-img" /> Logout
          </div>
        </div>
      </Modal>
      <Modal
        className="user-drawer-modal"
        open={notificationOpen}
        onCancel={() => setNotificationOpen(false)}
        maskClosable="true"
        closable="false"
        closeIcon={null}
        footer={null}
        width={340}
        maskStyle={{ backgroundColor: "transparent" }}
      >
        <div className="user-btn-div ">
          {user?.secondFactorAuth === null ? (
            <div className="twofa-div">
              <div style={{ display: "flex" }}>
                <img
                  src={icontwofa}
                  alt="twofa"
                  style={{ marginRight: "8px" }}
                />
                <p className="twafa-p m-0">
                  Your account security is low. Please set up two-factor
                  authentication now.
                </p>
              </div>
              <Button
                onClick={() => navigate(ROUTES.TWO_FACTOR_AUTH)}
                className="button-twofa"
              >
                Set Up 2FA Now
              </Button>
            </div>
          ) : null}

          <div style={{ marginBottom: "20px" }}>
            <div className="sb-justify-center-item-center">
              <p className="mt-0 mb-4 user-name-dropdown p-capitalize">
                {" "}
                {user?.firstName + " " + user?.lastName}
              </p>
              <Button
                className={` investor-status-button ${
                  user?.investorStatus === "ACTIVE"
                    ? "investor-status-button-active"
                    : user?.investorStatus === "MANUAL_REVIEW"
                    ? "investor-status-button-under-review"
                    : "investor-status-button-notactive"
                }`}
              >
                {" "}
                {user?.investorStatus === "ACTIVE"
                  ? "Verified"
                  : user?.investorStatus === "MANUAL_REVIEW"
                  ? "Under review"
                  : "Not verified"}
              </Button>
            </div>
            <p className="m-0 user-dropdown-name">{user?.email}</p>
            <p className="m-0 user-dropdown-name">
              {`User ID: ${user?.number}`}{" "}
            </p>
          </div>

          <div className="mt-20">
            <Link to={ROUTES.SETTINGS} className="profile-seeting-link">
              <img
                src={Profile_setting_icon}
                alt="setting_icon"
                style={{ marginRight: "4px" }}
              />
              Personal Settings
            </Link>
          </div>
          <div onClick={handleLogout} className="mt-20 logout-div">
            <img src={Logout_red_icon} alt="logout-img" /> Logout
          </div>
        </div>
      </Modal>
      {user?.hasSeenProductTour === false &&
        user?.productTourEnabled === true && (
          <ProductTour
            ref1={!condition ? null : isWideScreen ? ref1 : ref7}
            ref2={isWideScreen ? ref2 : ref8}
            ref3={isWideScreen ? ref3 : ref9}
            ref4={isWideScreen ? ref4 : ref10}
            ref5={isWideScreen ? ref5 : ref11}
            ref6={isWideScreen ? ref6 : ref12}
          />
        )}
      <Footer />
    </div>
  );
};

export default DashboardLayout;
